<template>
  <div class="recruitment">
    <van-sticky :offset-top="0">
      <div>
        <div
          style="
            position: relative;
            width: 100%;
            background: #0084f8;
            height: 50px;
          "
          @click="search = true"
        >
          <div class="search">
            <van-icon name="search" />
            <span style="margin-left: 15px">请选择搜索内容</span>
          </div>
        </div>
        <van-popup v-model="search" position="top" :style="{ height: '50%' }">
          <van-field
            v-model="listdata.title"
            label="名称"
            placeholder="请输入名称"
          />
          <van-field
            v-model="listdata.eName"
            label="企业"
            placeholder="请输入企业"
          />
          <van-field
            v-model="listdata.abiName"
            label="职业类型"
            placeholder="请选择职业类型"
            @click="abiName = true"
            readonly="readonly"
          />
          <van-popup v-model="abiName" position="bottom" get-container="body">
            <van-cascader
              title="请选择职业类型"
              :options="zhiye"
              @close="abiName = false"
              :field-names="{
                text: 'Name',
                value: 'Coding',
                children: 'Children',
              }"
              @finish="onabiName"
            />
          </van-popup>
          <van-field
            v-model="listdata.workAddr"
            label="地址"
            placeholder="请选择地址"
          />
          <van-button
            round
            type="info"
            style="width: 90%; margin: 25px 5% 10px"
            @click="serachGo"
            >搜 索</van-button
          >
          <van-button
            round
            style="width: 90%; margin: 5px 5%"
            @click="serachReset"
            >重 置</van-button
          >
        </van-popup>
      </div>
    </van-sticky>
    <!-- 职位列表 -->
    <div style="margin-bottom: 60px">
      <div
        class="postlist"
        v-for="item in list"
        :key="item"
        @click="todetail(item)"
      >
        <!-- <router-link :to="'/recruitment/detail/' + item.JId"> -->
        <div>
          <!-- 一 职位 薪资 -->
          <div class="listtitle">
            <span>{{ item.Title }}</span>
            <span style="color: red">{{ item.Salary }}</span>
          </div>
          <!-- 二 技能 学历 经验 -->
          <div class="listtwo">
            <span>工作经验:{{ item.WorkYear }}年</span>
            <span>学历要求:{{ item.EduName }}</span>
            <!-- <span>{{item.Skill}}</span> -->
          </div>
          <!-- 三 公司 人数 -->
          <div class="listthree">
            <span>企业:{{ item.EName }}</span>
            <span>
              <van-icon name="friends" />
              招聘人数:{{ item.RecruitNum }}人
            </span>
          </div>
          <!-- 四 招募人 地址 -->
          <div class="listfore">
            <span>
              <van-icon name="manager" />
              {{ item.StatusDesc }}
            </span>
            <span>
              <van-icon name="map-marked" />
              {{ item.WorkAddrName }}
            </span>
          </div>
        </div>
        <!-- </router-link> -->
      </div>
      <div
        v-if="hidden"
        style="text-align: center; font-size: 13px; margin: 15px 0px"
      >
        还没有发布的职位~
      </div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="manager-o" to="/list/firmlogin/"
        >企业端登录</van-tabbar-item
      >
      <van-tabbar-item icon="wap-home-o" to="/"
        >首页</van-tabbar-item
      >
      <van-tabbar-item icon="user-o" to="/list/recruitment/"
        >求职者登录</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import wx from "weixin-js-sdk";
import { getToken, setToken } from "@/utils/auth";
import { WeGetJobPage } from "@/api/ResumeJS";
import { GetAbilityList } from "@/api/REplat";
export default {
  data() {
    return {
      itemmark: "", //职位详情
      listdata: {
        accToken: getToken(), //token
        title: "", //名称
        abiCode: "", //职业编码
        abiName: "", //职业名称
        workAddr: "", //地址
        eName: "", //企业
      },
      active: 3,
      list: [], //列表
      zhiye: [],
      NativeCantonCodedata: [],
      search: false, //搜索框显示
      abiName: false,
      hidden: false,
    };
  },
  created() {
    this.GetJobPage();
    this.zidianlist();
    console.log(getToken());
  },
  methods: {
    onSearch() {
      this.GetJobPage();
    },
    //小程序跳转到小程序
    gotominiProgram(name) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/openminip?name=" + name,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    serachGo() {
      this.GetJobPage();
      this.search = false;
    },
    serachReset() {
      this.listdata = {
        accToken: getToken(), //token
        title: "", //名称
        abiCode: "", //职业编码
        abiName: "", //职业名称
        workAddr: "", //地址
        eName: "", //企业
      };
      this.GetJobPage();
      this.search = false;
    },
    // 获取职业列表
    GetJobPage() {
      WeGetJobPage(this.listdata).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          if (res.data.data.length == 0) {
            this.hidden = true;
            this.list = [];
          } else {
            this.hidden = false;
            this.list = res.data.data;
          }
        } else {
          this.hidden = true;
          Toast.fail("获取失败!" + res.data.msg);
        }
      });
    },
    // 跳转职位详情
    todetail(row) {
      this.itemmark = JSON.stringify(row);
      this.$router.push({
        name: "detail",
        query: { JId: this.itemmark },
      });
    },
    // 字典
    zidianlist: function () {
      GetAbilityList().then((res) => {
        this.zhiye = res.data.data;
      });
    },
    // 行业选中
    onabiName: function (val) {
      console.log(val);
      if (val.selectedOptions.length == 1) {
        this.listdata.abiCode = val.selectedOptions[0].Coding;
        this.listdata.abiName = val.selectedOptions[0].Name;
      } else {
        this.listdata.abiCode = val.selectedOptions[1].Coding;
        this.listdata.abiName = val.selectedOptions[1].Name;
      }
      this.abiName = false;
    },
  },
};
</script>
<style>
.recruitment {
  background: #eceff0;
}
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
.recruitment .van-nav-bar__title {
  color: #fff;
}
.recruitment .van-nav-bar__content {
  background: #0188fb;
}
.postlist {
  margin: 10px 0px;
  background: #fff;
}
.postlist .listtitle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 700;
  color: #000;
}
.postlist .listtwo {
  padding: 0px 10px;
  font-size: 12px;
}
.postlist .listtwo span {
  padding: 6px;
  background: rgb(238, 237, 237);
  margin-right: 8px;
  color: #666;
}
.postlist .listthree {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
  font-size: 14px;
}
.postlist .listthree span {
  margin-right: 30px;
  color: #666;
}
.postlist .listfore {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0px 0px 15px 10px;
  font-size: 13px;
}
.postlist .listfore span {
  margin-right: 15px;
  color: #a3a7a8;
}
.van-search__action {
  margin-right: 10px !important;
  color: #fff;
}
.van-search__action:active {
  background: #0188fb;
}
.nameResume {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #0188fb;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
.nameResumes {
  position: fixed;
  right: 100px;
  bottom: 20px;
  background: #0188fb;
  text-align: center;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
.search {
  display: flex;
  align-items: center;
  position: absolute;
  background: rgb(236, 238, 240);
  left: 5%;
  top: 10px;
  width: 85%;
  height: 30px;
  padding-left: 15px;
  font-size: 14px;
}
</style>